<template>
    <div>
        <div class="mb-3 demo-spin-article">
            <ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
                <div class=" tw-flex tw-ml-12">
                    <div class="tw-space-x-4">
                        <label class="col-label form-label tw-text-xs">{{
                            $t('monthlyDeduction.year')
                        }}</label>
                        <date-Picker :value="model.cycle_year" type="year" format="yyyy" placeholder="Select Year"
                            style="width:320px" @on-change="cycleYearChange" :class="{
                                'is-invalid': errors.has('cycle_year')
                            }">
                        </date-Picker>
                        <div class="invalid-feedback" v-if="errors.has('cycle_year')">
                            {{ errors.first('cycle_year') }}
                        </div>
                    </div>
                    <div class=" tw-space-x-4">
                        <label class="col-label form-label tw-text-xs tw-ml-12">{{ $t('monthlyDeduction.month') }}</label>
                        <DatePicker :value="model.cycle_month" type="month" placeholder="Select Month"
                            style="width:320px" format="MM" @on-change="cycleMonthChange" :class="{
                                'is-invalid': errors.has('cycle_month')
                            }">
                        </DatePicker>
                        <div class="invalid-feedback" v-if="errors.has('cycle_month')">
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                </div>
                <button type="button" class="btn ts-whitespace-no-wrap btn-default btn-primary mr-2" :waiting="waiting"
                    :disabled="waiting_new || waiting" @click.prevent="onGenerate">
                    Submit
                </button>
            </ts-panel-wrapper>
            <div class="mb-3">
                <Input v-model="search" search :placeholder="$t('monthlyDeduction.search')" />
            </div>
            <Table highlight-row ref="selection" :columns="columns" :data="employees"
                @on-select="setEmployeeIdListSelected" @on-select-cancel="setEmployeeIdListSelected"
                @on-select-all="setEmployeeIdListSelected" @on-select-all-cancel="setEmployeeIdListSelected" stripe>
                <template slot-scope="{ row }" slot="photo">
                    <img class="tw-rounded tw-h-8" v-lazy="{
                        src: photo(row.employee),
                        error:
                            row.employee.sex == 'Male'
                                ? require('@/assets/staff-default-man.png')
                                : require('@/assets/staff-default-women.png'),
                        loading: require('@/assets/Rolling-1s-200px.svg'),
                        preLoad: 1.3,
                        attempt: 1
                    }" />
                </template>
                <template slot-scope="{ row }" slot="card_id">
                    {{ row.employee ? row.employee.card_id : '' }}
                </template>
                <template slot-scope="{ row }" slot="employee_name_en">
                    {{ row.employee ? row.employee.employee_name_en : '' }}|
                    {{ row.employee ? row.employee.employee_name_kh : '' }}
                </template>
                <template slot-scope="{ row }" slot="leave_type_en">
                    {{ row.leave_type ? row.leave_type.leave_type_en : '' }}|
                    {{ row.leave_type ? row.leave_type.leave_type_kh : '' }}
                </template>
                <!-- <template slot-scope="{ row }" slot="total_day_show">
                    {{ row.leave_type ? row.leave_type.total_day_show : '' }}
                </template> -->
            </Table>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import { Errors } from 'form-backend-validation'
import moment from 'moment'

export default {
    name: 'monthlyDeductionUnpaidLeave',
    data() {
        return {
            selectedLeaveRecords: [],
            resources: [],
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            search: '',
            model: {
                deduction_id: null,
                object_detail: [],
                cycle_year: moment().format('YYYY'),
                cycle_month: moment().format('MM')
            }
        }
    },
    computed: {
        employees() {
            if (this.resources.length > 0 && this.search) {
                return this.resources.filter(
                    p =>
                        p.employee.card_id
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.employee.employee_name_en
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.employee.employee_name_kh
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                )
            }
            return this.resources
        },
        columns() {
            return [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center'
                },
                {
                    title: 'Photo',
                    slot: 'photo',
                    align: 'center',
                    width: 80
                },
                {
                    title: 'Employee',
                    slot: 'card_id',
                    align: 'center',

                    width: 120
                },
                {
                    title: 'Employee Name',
                    slot: 'employee_name_en',
                    width: 200
                },
                {
                    title: 'Date From',
                    key: 'date_from',
                    align: 'center',
                    width: 120
                },
                {
                    title: 'Time From',
                    key: 'time_from',
                    align: 'center',
                    width: 120
                },
                {
                    title: 'Date To',
                    key: 'date_to',
                    align: 'center',
                    width: 120
                },
                {
                    title: 'Time To',
                    key: 'time_to',
                    align: 'center',
                    width: 120
                },
                {
                    title: 'Leave Type',
                    slot: 'leave_type_en',
                    align: 'center',
                    width: 250
                },
                {
                    title: 'ToTal',
                    align: 'center',
                    key: 'total_day',
                    width: 80
                }
            ]
        }
    },
    methods: {
        ...mapActions('payroll/monthlyDeduction', ['getLeaveReocrd']),
        fetchResource() {
            this.loading = true
            this.getLeaveReocrd()
                .then(res => {
                    this.resources = res.data
                    this.$nextTick(() => this.$refs.selection.selectAll(true))
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        photo(record) {
            if (record.photo) {
                return record.photo
            }

            return record.sex == 'Male'
                ? require('@/assets/staff-default-man.png')
                : require('@/assets/staff-default-women.png')
        },
        onGenerate() {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch(
                    'payroll/monthlyDeduction/importUnpaidLeave',
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    if(response.message_id == 0)
                    {
                        this.clearInput()
                        this.$emit('importSuccess')
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.errors = new Errors()
            this.model.deduction_id = null
            this.model.object_detail = null
            this.model.cycle_year = moment().format('YYYY')
            this.model.cycle_month = moment().format('MM')
        },
        cycleYearChange(value) {
            this.model.cycle_year = value
        },
        cycleMonthChange(value) {
            this.model.cycle_month = value
        },
        setEmployeeIdListSelected(records) {
            this.model.object_detail = []
            if (records.length > 0) {
                records.map(row => {
                    this.model.object_detail.push(row.lr_id)
                })
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: 'MONTHLY DEDUCTION',
                desc: not.text
            })
        }
    }
}
</script>
