<template>
    <div>
        <div class="mb-3 demo-spin-article">
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class=" tw-flex tw-ml-12">
                    <div class="tw-space-x-4">
                        <label class="col-label form-label tw-text-xs">{{
                            $t("monthlyDeduction.year")
                        }}</label>
                        <date-Picker
                            :value="model.cycle_year"
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            style="width:240px"
                            @on-change="cycleYearChange"
                            :class="{
                                'is-invalid': errors.has('cycle_year')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_year')"
                        >
                            {{ errors.first("cycle_year") }}
                        </div>
                    </div>
                    <div class=" tw-space-x-4">
                        <label
                            class="col-label form-label tw-text-xs tw-ml-12"
                            >{{ $t("monthlyDeduction.month") }}</label
                        >
                        <DatePicker
                            :value="model.cycle_month"
                            type="month"
                            placeholder="Select Month"
                            style="width:240px"
                            format="MM"
                            @on-change="cycleMonthChange"
                            :class="{
                                'is-invalid': errors.has('cycle_month')
                            }"
                        >
                        </DatePicker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first("cycle_month") }}
                        </div>
                    </div>
                    <div class=" tw-space-x-4 tw-flex">
                        <label
                            class="col-label form-label tw-text-xs tw-ml-12 tw-mt-2"
                        >
                            {{ $t("monthlyDeduction.cycleOrder") }}
                        </label>
                        <Select
                            v-model="model.cycle_no"
                            style="width:240px"
                            @on-change="cycleOption"
                        >
                            <Option
                                v-for="c in cycleOrders"
                                :key="c.value"
                                :value="c.value"
                                >{{ c.text }}
                            </Option>
                        </Select>
                    </div>
                </div>
                <ts-button
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting"
                    @click.prevent="onGenerate"
                >
                    Submit
                </ts-button>
            </ts-panel-wrapper>
            <div class="mb-3">
                <Input
                    v-model="search"
                    search
                    :placeholder="$t('monthlyDeduction.search')"
                />
            </div>
            <Table
                highlight-row
                ref="selection"
                :columns="columns"
                :data="employees"
                @on-select="setEmployeeIdListSelected"
                @on-select-cancel="setEmployeeIdListSelected"
                @on-select-all="setEmployeeIdListSelected"
                @on-select-all-cancel="setEmployeeIdListSelected"
                max-height="500"
                size="small"
                stripe
            >
                <template slot-scope="{ row }" slot="photo">
                    <img
                        class="tw-rounded tw-h-8"
                        v-lazy="{
                            src: photo(row),
                            error:
                                row.sex == 'Male'
                                    ? require('@/assets/staff-default-man.png')
                                    : require('@/assets/staff-default-women.png'),
                            loading: require('@/assets/Rolling-1s-200px.svg'),
                            preLoad: 1.3,
                            attempt: 1
                        }"
                    />
                </template>
                <template slot-scope="{ row }" slot="employee_name">
                    {{
                        locale == "kh"
                            ? row.employee_name_kh
                            : row.employee_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="installment_amount">
                    <span class="tw-font-bold text-blue">{{
                        formatNumber(row.installment_amount, row.currency_id) +
                            " " +
                            signBycurrencyId(row.currency_id)
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="outstanding_amount">
                    <span class="tw-font-bold text-warning">{{
                        formatNumber(row.outstanding_amount, row.currency_id) +
                            " " +
                            signBycurrencyId(row.currency_id)
                    }}</span>
                </template>
            </Table>
            <div class="text-danger" v-if="errors.has('object_detail')">
                {{ errors.first("object_detail") }}
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";

export default {
    name: "monthlyDeductionInstallment",
    data() {
        return {
            selectedLeaveRecords: [],
            resources: [],
            errors: new Errors(),
            loading: false,
            waiting: false,
            search: "",
            searchCycle: "",
            model: {
                cycle_no: 2,
                deduction_id: null,
                object_detail: [],
                cycle_year: moment().format("YYYY"),
                cycle_month: moment().format("MM")
            },
            cycleOrders: [
                {
                    value: 0,
                    text: "--Select--"
                },
                {
                    value: 1,
                    text: "First Cycle"
                },
                {
                    value: 2,
                    text: "Second Cycle"
                }
            ]
        };
    },
    computed: {
        ...mapGetters(["formatNumber", "signBycurrencyId"]),
        employees() {
            if (this.resources.length > 0 && this.search) {
                return this.resources.filter(
                    p =>
                        p.card_id
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.employee_name_en
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.employee_name_kh
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.customer_code
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.installment_no
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                );
            }
            return this.resources;
        },
        columns() {
            return [
                {
                    type: "selection",
                    minWidth: 60,
                    align: "center"
                },
                {
                    title: "Photo",
                    slot: "photo",
                    align: "center",
                    minWidth: 80
                },
                {
                    title: "Branch Code",
                    key: "branch_code",
                    align: "center",
                    minWidth: 120
                },
                {
                    title: "ID",
                    key: "card_id",
                    align: "center",
                    minWidth: 90
                },
                {
                    title: "Employee Name",
                    slot: "employee_name",
                    minWidth: 150
                },
                {
                    title: "Sex",
                    key: "sex",
                    align: "center",
                    minWidth: 100
                },
                {
                    title: "Customer Code",
                    align: "center",
                    key: "customer_code",
                    minWidth: 150
                },
                {
                    title: "Loan Number",
                    key: "installment_no",
                    align: "center",
                    minWidth: 145
                },
                {
                    title: "Installment Amount",
                    align: "right",
                    slot: "installment_amount",
                    minWidth: 110
                },
                {
                    title: "Cycle Payment Date",
                    key: "cycle_payment_date",
                    align: "center",
                    minWidth: 160
                },
                {
                    title: "Outstanding Amount",
                    slot: "outstanding_amount",
                    align: "right",
                    minWidth: 115
                },
                {
                    title: "Loan Type",
                    key: "installment_type",
                    align: "left",
                    minWidth: 180
                },
                {
                    title: "Position Name",
                    key: "position_name",
                    align: "left",
                    minWidth: 170
                }
            ];
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        ...mapActions("payroll/monthlyDeduction", ["getInstallment"]),
        fetchResource() {
            this.loading = true;
            this.getInstallment({
                cycle_no: this.model.cycle_no
            })
                .then(res => {
                    this.resources = res.data;
                    this.$nextTick(() => this.$refs.selection.selectAll(true));
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.sex == "Male"
                ? require("@/assets/staff-default-man.png")
                : require("@/assets/staff-default-women.png");
        },
        onGenerate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "payroll/monthlyDeduction/importInstallment",
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if (response.message_id == 0) {
                        this.clearInput();
                        this.$emit("importSuccess");
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            this.errors = new Errors();
            this.model.cycle_no = 2;
            this.model.deduction_id = null;
            this.model.object_detail = null;
            this.model.cycle_year = moment().format("YYYY");
            this.model.cycle_month = moment().format("MM");
        },
        cycleYearChange(value) {
            this.model.cycle_year = value;
        },
        cycleMonthChange(value) {
            this.model.cycle_month = value;
        },
        setEmployeeIdListSelected(records) {
            this.model.object_detail = [];
            records.map(row => {
                this.model.object_detail.push({
                    employee_id: row.employee_id,
                    loan_id: row.loan_id,
                    loan_number: row.installment_no,
                    cycle_payment_date: row.cycle_payment_date,
                    installment_amount: row.installment_amount
                });
            });
        },
        cycleOption(value) {
            this.model.cycle_no = value;
            this.fetchResource({
                cycle_no: value
            });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONTHLY DEDUCTION",
                desc: not.text
            });
        }
    }
};
</script>
