<template>
    <div>
        <div class="demo-spin-article">
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-flex tw-ml-12">
                    <div class="tw-space-x-4">
                        <label class="col-label form-label tw-text-xs">{{
                            $t("monthlyDeduction.year")
                        }}</label>
                        <date-Picker
                            :value="model.cycle_year"
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            style="width:240px"
                            @on-change="cycleYearChange"
                            :class="{
                                'is-invalid': errors.has('cycle_year')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_year')"
                        >
                            {{ errors.first("cycle_year") }}
                        </div>
                    </div>
                    <div class=" tw-space-x-4">
                        <label
                            class="col-label form-label tw-text-xs tw-ml-12"
                            >{{ $t("monthlyDeduction.month") }}</label
                        >
                        <DatePicker
                            :value="model.cycle_month"
                            type="month"
                            placeholder="Select Month"
                            style="width:240px"
                            format="MM"
                            @on-change="cycleMonthChange"
                            :class="{
                                'is-invalid': errors.has('cycle_month')
                            }"
                        >
                        </DatePicker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first("cycle_month") }}
                        </div>
                    </div>
                    <div class=" tw-space-x-4 tw-flex">
                        <label
                            class="col-label form-label tw-text-xs tw-ml-12 tw-mt-2"
                        >
                            {{ $t("monthlyDeduction.cycleOrder") }}
                        </label>
                        <Select
                            v-model="model.cycle_no"
                            style="width:240px"
                            @on-change="cycleOption"
                        >
                            <Option
                                v-for="c in cycleOrders"
                                :key="c.value"
                                :value="c.value"
                                >{{ c.text }}
                            </Option>
                        </Select>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="mb-3">
                <Input
                    v-model="search"
                    search
                    :placeholder="$t('monthlyDeduction.search')"
                />
            </div>
            <Table
                highlight-row
                :columns="columns"
                :data="employees"
                max-height="500"
                size="small"
                stripe
            >
                <template slot-scope="{ row }" slot="branch_name">
                    {{
                        locale == "kh"
                            ? row.branch.branch_name_kh
                            : row.branch.branch_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="card_id">
                    {{
                        row.employee_profile ? row.employee_profile.card_id : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="employee_name">
                    {{
                        row.employee_profile
                            ? locale == "kh"
                                ? row.employee_profile.employee_name_kh
                                : row.employee_profile.employee_name_en
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="sex">
                    {{ row.employee_profile ? row.employee_profile.sex : "" }}
                </template>
                <template slot-scope="{ row }" slot="customer_name">
                    {{
                        locale == "kh"
                            ? row.customer_name_kh
                            : row.customer_name_en
                    }}
                </template>
                <template slot-scope="{ row }" slot="installment_amount">
                    <span class="tw-font-bold text-blue">{{
                        formatNumber(row.deduction, row.currency_id) +
                            " " +
                            signBycurrencyId(row.currency_id)
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="loan_type">
                    {{ locale == "kh" ? row.loan_type_kh : row.loan_type_en }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <i v-if="row.loading && !row.is_completed" class="fas fa-sync fa-spin fa-2x text-warning"></i>
                    <i v-if="!row.loading && row.is_completed" class="fas fa-check-circle fa-2x text-success"></i>
                </template>
            </Table>
            <div class="mt-3 tw-flex tw-justify-end tw-space-x-2">
                <ts-button
                    @click.prevent="$emit('close')"
                >
                    Cancle
                </ts-button>
                <ts-button
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting || resources.length <= 0"
                    @click.prevent="onGenerate"
                >
                    Submit
                </ts-button>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";

export default {
    name: "clearInstallment",
    data() {
        return {
            selectedLeaveRecords: [],
            resources: [],
            errors: new Errors(),
            loading: false,
            waiting: false,
            search: "",
            successCreate: [],
            show_alert: false,
            percent: 100,
            model: {
                cycle_no: 2,
                cycle_year: moment().format("YYYY"),
                cycle_month: moment().format("MM")
            },
            cycleOrders: [
                {
                    value: 0,
                    text: "--Select--"
                },
                {
                    value: 1,
                    text: "First Cycle"
                },
                {
                    value: 2,
                    text: "Second Cycle"
                }
            ]
        };
    },
    computed: {
        ...mapGetters(["formatNumber", "signBycurrencyId"]),
        color() {
            let color = "#2db7f5";
            if (this.percent == 100) {
                color = "#5cb85c";
            }
            return color;
        },
        employees() {
            if (this.resources.length > 0 && this.search) {
                return this.resources.filter(
                    p =>
                        p.card_id
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.employee_name_en
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.employee_name_kh
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.customer_code
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        p.installment_no
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                );
            }
            return this.resources;
        },
        columns() {
            return [
                {
                    title: "Branch Name",
                    slot: "branch_name",
                    align: "center",
                    minWidth: 120
                },
                {
                    title: "ID",
                    slot: "card_id",
                    align: "center",
                    minWidth: 90
                },
                {
                    title: "Employee Name",
                    slot: "employee_name",
                    minWidth: 150
                },
                {
                    title: "Sex",
                    slot: "sex",
                    align: "center",
                    minWidth: 100
                },
                {
                    title: "Loan Number",
                    key: "loan_number",
                    align: "center",
                    minWidth: 145
                },
                {
                    title: "Installment Amount",
                    align: "right",
                    slot: "installment_amount",
                    minWidth: 110
                },
                {
                    title: "Cycle Payment Date",
                    key: "cycle_payment_date",
                    align: "center",
                    minWidth: 160
                },
                {
                    title: "Customer Code",
                    align: "center",
                    key: "customer_code",
                    minWidth: 150
                },
                {
                    title: "Customer Name",
                    slot: "customer_name",
                    minWidth: 150
                },
                {
                    title: "Loan Type",
                    slot: "loan_type",
                    minWidth: 180
                },
                {
                    title: "Status",
                    slot: "status",
                    align: "center",
                    fixed: "right",
                    minWidth: 100
                }
            ];
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        ...mapActions("payroll/monthlyDeduction", ["getInstallmentDeduction"]),
        fetchResource() {
            this.loading = true;
            this.resources = [];
            this.getInstallmentDeduction({
                filter: {
                    cycle_year: this.model.cycle_year,
                    cycle_month: this.model.cycle_month,
                    cycle_number: this.model.cycle_no
                }
            })
                .then(res => {
                    res.data.forEach(resource => {
                        this.resources.push(Object.assign(resource, { is_completed: false, loading: false }))
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onGenerate() {
            this.errors = new Errors();
            this.waiting = true;
            this.successCreate = [];
            this.show_alert = false;
            for (const item of this.resources) {
                item.loading = true
                await this.$store
                    .dispatch("payroll/monthlyDeduction/clearInstallment", item)
                    .then(() => {
                        item.loading = false
                        item.is_completed = true
                        this.successCreate.push(item);
                    })
                    .catch(error => {
                        item.loading = false
                        this.show_alert = true;
                        this.waiting = false;
                        this.errors = new Errors(error.errors);
                        this.notice({ type: "error", text: error.message });
                    });
                if (this.show_alert) {
                    break;
                }
            }
            this.successCreate.forEach(async el => {
                await this.resources.splice(this.resources.indexOf(el), 1);
            });
            this.waiting = false;
            if (this.resources.length == 0) {
                this.notice({
                    type: "success",
                    text: "Action completed successfully!"
                });
                this.clearInput();
                this.$emit("clearSuccess");
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.cycle_no = 2;
            this.model.cycle_year = moment().format("YYYY");
            this.model.cycle_month = moment().format("MM");
        },
        cycleYearChange(value) {
            this.loading = true;
            this.resources = [];
            this.model.cycle_year = value;
            this.getInstallmentDeduction({
                filter: {
                    cycle_number: this.model.cycle_no,
                    cycle_year: value,
                    cycle_month: this.model.cycle_month
                }
            })
                .then(res => {
                    res.data.forEach(resource => {
                        this.resources.push(Object.assign(resource, { is_completed: false, loading: false }))
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cycleMonthChange(value) {
            this.loading = true;
            this.resources = [];
            this.model.cycle_month = value;
            this.getInstallmentDeduction({
                filter: {
                    cycle_number: this.model.cycle_no,
                    cycle_year: this.model.cycle_year,
                    cycle_month: value
                }
            })
                .then(res => {
                    res.data.forEach(resource => {
                        this.resources.push(Object.assign(resource, { is_completed: false, loading: false }))
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cycleOption(value) {
            this.loading = true;
            this.resources = [];
            this.model.cycle_no = value;
            this.getInstallmentDeduction({
                filter: {
                    cycle_number: value,
                    cycle_year: this.model.cycle_year,
                    cycle_month: this.model.cycle_month
                }
            })
                .then(res => {
                    res.data.forEach(resource => {
                        this.resources.push(Object.assign(resource, { is_completed: false, loading: false }))
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONTHLY DEDUCTION",
                desc: not.text
            });
        }
    }
};
</script>
